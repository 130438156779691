import React, { useState, useEffect } from "react"
import { Link as GatsbyLink } from 'gatsby'
import LayoutAccount from "../components/layouts/layoutAccount"
import { Box, Button, Flex, Image, Spinner, Stack, Text, Heading } from "@chakra-ui/react"
import { FormattedMessage } from 'react-intl';
import Seo from '../components/Seo/';
import logo from '../images/logo.svg'
import withLocation from "../utils/withLocation";

const AccountValidationTpl = (props) => {

  const [isAdValidate, setAdValidate] = useState(false)

  useEffect(() => validateAd(), []);

  const validateAd = () => {
    if (props.search.token) {
      fetch(process.env.GATSBY_API_URL + 'annonces/' + props.search.token + '/publish/', { method: 'POST' })
        .then(res => res.json())
        .then(
          (result) => {
            if (!result) {
              alert('error')
            }
            else {
              setAdValidate(true)
              // localStorage.setItem('WbUserToken', result.email_token)
              // localStorage.setItem('UserID', result.id)
              // navigate( siteConfig.links.account[ process.env.GATSBY_LANG ])
            }
          },
          (error) => {

          }
        )
    }
  }


  const title = {
    "fr": "Validation de votre compte"
    ,
    "en": "Validation of your account"
  }
  const description = {
    "fr": "Cette page vous permet de valider votre compte. Votre annonce sera ensuite publiée sur Wanboat.fr"
    ,
    "en": "This page allows you to validate your account. Your ad will then be published on Wanboat.fr."
  };

  return (
    <LayoutAccount lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]
        }
        slug={'/'}
      />
      <Box >
        <Flex
          p='2rem'
          borderBottom={{ base: 'none', lg: 'solid 1px' }}
          borderBottomColor={{ lg: 'gray.100' }}
          justifyContent='space-between'
        >
          <Box>
            <Button as={GatsbyLink} to="/">
              <FormattedMessage id="main.back.to_webiste" />
            </Button>
          </Box>
          <Box>
            <Image src={logo} w="50px" h="50px" />
          </Box>
        </Flex>


        <Stack p='2rem' maxW='900px' mx='auto' spacing="2rem">
          <Box>
            {!isAdValidate ?
              <Flex alignItems="center">
                <Spinner
                  size='sm'
                  mr='.5rem'
                />
                {/* Validation de votre compte en cours. */}
                <FormattedMessage id="account.validation.waiting.message" />
              </Flex>
              :
              <Stack spacing="1rem">
                <Heading
                  as="h1"
                  fontSize="30px"
                >
                  <span role="img" aria-label="Cool">💪</span>&nbsp;
                  <FormattedMessage id="account.validation.its.ok" />
                </Heading>
                <Text>
                  <FormattedMessage id="account.validation.notice.1" />
                </Text>
                <Text>
                  <FormattedMessage id="account.validation.notice.2" />
                </Text>
                <Box>
                  <Button><FormattedMessage id="account.validation.back.to.homepage" /></Button>
                </Box>
              </Stack>
            }
          </Box>
        </Stack>
      </Box>
    </LayoutAccount>
  )
}
export default withLocation(AccountValidationTpl)